import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { useViewportSize } from 'hooks/useViewportSize';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { actions } from 'store/GuideUse/actions';
import { actions as commonActions } from 'store/Common/actions';
import {
  getIsMapSettingsOpenSelector,
  getGuideDataSelector,
} from 'store/GuideUse/selectors';
import {
  getDistanceUnitSelector,
  getElevationUnitSelector,
  getClockwiseDirectionTrailsSelector,
} from 'store/Common/selectors';
import {
  distanceUnitName,
  metersInDistanceUnit,
  shortDistanceUnitName,
} from 'constants/common/distanceUnits';

import { S } from './MapSettingsModal.styles';
import { BaseSwitch } from '../../../controls/BaseSwitch/BaseSwitch';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSettingsUtils } from 'utils/settingsUtils';

const MapSettingsModalComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { applyDistanceUnitChanging } = useSettingsUtils();
  const isMapSettingsOpen = useSelector(getIsMapSettingsOpenSelector);
  const actualDistanceUnit = useSelector(getDistanceUnitSelector);
  const actualElevationUnit = useSelector(getElevationUnitSelector);
  const actualClockwiseDirectionTrails = useSelector(
    getClockwiseDirectionTrailsSelector
  );
  const { guideId, primaryDirection, secondaryDirection } =
    useSelector(getGuideDataSelector);

  const handleHideMapSettings = useCallback(() => {
    dispatch(actions.setIsMapSettingsOpen(false));
  }, [dispatch]);

  const handleChangeDistanceUnit = (checked: boolean) => {
      applyDistanceUnitChanging(checked)
  };

  const handleChangeTrailDirection = (checked: boolean) => {
    const localStorage = LocalStorageManager.getInstance();

    const clockwiseDirectionTrails =
      localStorage.getItem('clockwiseDirectionTrails')?.split(';') || [];

    const newClockwiseDirectionTrails = checked
      ? clockwiseDirectionTrails.filter(
          (trailId: string) => trailId !== guideId
        )
      : [...clockwiseDirectionTrails, guideId];

    localStorage.setItem(
      'clockwiseDirectionTrails',
      newClockwiseDirectionTrails.join(';')
    );

    dispatch(
      commonActions.setClockwiseDirectionTrails(newClockwiseDirectionTrails)
    );
  };

  useEffect(() => {
    return () => {
      handleHideMapSettings();
    };
  }, [handleHideMapSettings]);

  let intl = useIntl()
  return (
    <S.Modal
      visible={isMapSettingsOpen}
      onCancel={handleHideMapSettings}
      zIndex={1002}
      footer={null}
    >
      <S.Container>
        <S.Header>
          <S.Title><FormattedMessage id="settings"/></S.Title>
        </S.Header>
        <S.Body>
          <S.Section>
            <S.SectionTitle><FormattedMessage id="general_settings"/></S.SectionTitle>
            <S.Option>
              <S.OptionName><FormattedMessage id="distance_units"/></S.OptionName>
              <S.OptionControl>
                <S.ValueName>
                  <FormattedMessage id={actualElevationUnit.name}/>/<FormattedMessage id={actualDistanceUnit.name}/>
                </S.ValueName>
                <S.Control>
                  <BaseSwitch
                    onChange={handleChangeDistanceUnit}
                    defaultChecked={
                      actualDistanceUnit.name === distanceUnitName.KM
                    }
                  />
                </S.Control>
              </S.OptionControl>
            </S.Option>
            {primaryDirection && secondaryDirection && (
              <S.Option>
                <S.OptionName><FormattedMessage id="trail_direction"/></S.OptionName>
                <S.OptionControl>
                  <S.ValueName>
                    {actualClockwiseDirectionTrails.includes(guideId)
                      ? intl.formatMessage({id:secondaryDirection.toLowerCase()})
                      : intl.formatMessage({id:primaryDirection.toLowerCase()})}
                  </S.ValueName>
                  <S.Control>
                    <BaseSwitch
                      onChange={handleChangeTrailDirection}
                      defaultChecked={
                        !actualClockwiseDirectionTrails.includes(guideId)
                      }
                    />
                  </S.Control>
                </S.OptionControl>
              </S.Option>
            )}
          </S.Section>
        </S.Body>
      </S.Container>
    </S.Modal>
  );
};

export const MapSettingsModal = memo(MapSettingsModalComponent, isEqual);
