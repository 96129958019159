import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';

export const S = {
  PointCardContainer: styled.div``,
  Container: styled.div`
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid
      ${({ theme }) => theme.pointCard.colors.backgroundContainer};
    cursor: pointer;
  `,
  Icon: styled.img.attrs(({ src }) => ({
    src,
  }))`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 45px;
    height: 45px;
    border: 3px solid ${({ theme }) => theme.pointCard.colors.borderIcon};
    border-radius: 5px;

    & svg {
      width: 70px;
      height: 70px;
    }

    & svg g {
      fill: ${({ color }) => `#${color}`};
      width: 100%;
      height: 100%;
    }

    & svg path {
      width: 100%;
      height: 100%;
    }
  `,
  Ic: styled.div`
    width: 100%;
    height: 100%;
  `,
  PointInfo: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 84px);
    flex: 1;
  `,
  Title: styled.span`
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  ShortInfo: styled.ul`
    margin: 0;
    padding: 0;
  `,
  Li: styled.li`
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: 18px;
    }

    &:not(:last-child)::after {
      margin-right: 12px;
      position: absolute;
      top: 10px;
      right: -22px;
      display: inline-block;
      content: '';
      height: 3px;
      width: 3px;
      border-radius: 50px;
      background: ${({ theme }) =>
        theme.pointCard.colors.backgroundSortInfoDivider};
    }
  `,
  Location: styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  RightArrowIcon: styled(RightOutlined)`
    color: ${({ theme }) => theme.pointCard.colors.backgroundArrowIcon};
  `,
  PointDetails: styled.div``,
};
