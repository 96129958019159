import { memo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { TrailsStore } from 'views/TrailsStore/TrailsStore';
import { useViewportSize } from 'hooks/useViewportSize';
import { actions } from 'store/TrailsStore/actions';
import { actions as cartActions } from '../../store/CartStore';
import {
  getAllTrailsListSelector,
  getGuidesSelector,
  getIsFetchingAllTrailsListSelector,
  getSelectedFiltersListLengthSelector,
  getIsDetailsDisplayingSelector,
  getIsActiveSubscriptionSelector,
  getIsFetchingSubscriptionInfoSelector,
} from 'store/TrailsStore/selectors';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';

const TrailsStoreContainerComponent: React.FC = () => {
  const { isMobile } = useViewportSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const allGuidesList = useSelector(getAllTrailsListSelector);
  const guidesList = useSelector(getGuidesSelector);
  const isFetchingTrailsList = useSelector(getIsFetchingAllTrailsListSelector);
  const selectedFiltersListLength = useSelector(getSelectedFiltersListLengthSelector);
  const isActiveSubscription = useSelector(getIsActiveSubscriptionSelector);
  const isFetchingSubscriptionInfo = useSelector(getIsFetchingSubscriptionInfoSelector);

  const isDetailsDisplaying = useSelector(getIsDetailsDisplayingSelector);
  const [isCardsMenuMode, setIsCardsMenuMode] = useState<boolean>(true);
  const [isFilterShown, setIsFilterShown] = useState<boolean>(false);
  const [isGuidesReloadingNeeded, setIsGuidesReloadingNeeded] = useState<boolean>(false);
  const [cardsMenuScrollPosition, setCardsMenuScrollPosition] = useState<number>(0);
  const [expandedGuideIds, setExpandedGuideIds] = useState<string[]>([]);

  useEffect(() => {
    if ((!allGuidesList.length || isGuidesReloadingNeeded) && !isFetchingTrailsList) {
      setIsGuidesReloadingNeeded(false)
      dispatch(actions.getAllTrailsListRequest());
    }
  }, [dispatch, allGuidesList, isFetchingTrailsList, isGuidesReloadingNeeded]);

  useEffect(() => {
    if (!isFetchingSubscriptionInfo && isActiveSubscription == null){
      dispatch(actions.getActiveSubscriptionRequest());
    }
  }, [dispatch, isActiveSubscription, isFetchingSubscriptionInfo]);

  const onSetIsCardsMenuMode = () => {
    setIsCardsMenuMode(prev => !prev);
  };

  const handleCountryChanged = useCallback(() => {
    setIsGuidesReloadingNeeded(true)

    dispatch(cartActions.requestSetCartBuyerIdentity({}));
  },
  [dispatch]);

  const handleGoToGuideView = (guideName: string) => {
    dispatch(actions.setIsDetailsDisplaying(true));
    history.push(`/guides/${encodeURIComponent(guideName)}`);
  };

  const handleSetCardsMenuScrollPosition = useCallback(
    (pageYOffset: number) => {
      setCardsMenuScrollPosition(pageYOffset);
    },
    []
  );

  const handleIsURLIncludingGuideId = (path?: string) => {
    if (!path) {
      return false;
    }

    if (path.lastIndexOf('guides/') === -1) {
      return false;
    }

    const prevGuideIdPosition = path.lastIndexOf('guides/') + 'guides/'.length;

    return path.slice(prevGuideIdPosition) ? true : false;
  };

  const handleSetExpandedGuideIds = useCallback((newIds: string[]) => {
    setExpandedGuideIds(newIds);
  }, []);

  useEffect(() => {
    handleIsURLIncludingGuideId(history.location.pathname) &&
      dispatch(actions.setIsDetailsDisplaying(true));
  }, [history, history.location, history.location.pathname, dispatch]);

  // useEffect(() => {
  //   isMobile && !isDetailsDisplaying && history.push('/guides/');
  // }, [isMobile, isDetailsDisplaying, history]);

  return (
    <TrailsStore
      isCardsMenuMode={isCardsMenuMode}
      onSetIsCardsMenuMode={onSetIsCardsMenuMode}
      trailsList={guidesList}
      isFetchingTrailsList={isFetchingTrailsList}
      isFilterShown={isFilterShown}
      setIsFilterShown={setIsFilterShown}
      isMobile={isMobile}
      selectedFiltersListLength={selectedFiltersListLength}
      cardsMenuScrollPosition={cardsMenuScrollPosition}
      isDetailsDisplaying={isDetailsDisplaying}
      handleSetCardsMenuScrollPosition={handleSetCardsMenuScrollPosition}
      handleGoToGuideView={handleGoToGuideView}
      handleCountryChanged={handleCountryChanged}
      expandedGuideIds={expandedGuideIds}
      handleSetExpandedGuideIds={handleSetExpandedGuideIds}
    />
  );
};

export const TrailsStoreContainer = memo(
  TrailsStoreContainerComponent,
  isEqual
);
