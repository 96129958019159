import {
  memo,
  useState,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { CarouselRef } from 'antd/lib/carousel';
import { Carousel } from 'shared/Carousel/Carousel';
import { TrailCard, LoadedCard } from 'shared/Card/Card';
import { actions } from 'store/TrailsStore/actions';
import { getSelectedTrailGuideIdSelector } from 'store/TrailsStore/selectors';
import { getDistanceUnitSelector } from 'store/Common/selectors';
import {
  CartItemsHashmapCollection,
  selectCartItemsHashmapCollection,
  selectItemsBeingAddedToCart,
} from 'store/CartStore';
import { AppState } from 'store/rootReducer';

import { S } from './TrailsCorousel.styles';
import { TrailsCorouselProps } from 'containers/TrailsStore/TrailsStore.interface';

const TrailsCorouselComponent: React.FC<TrailsCorouselProps> = ({
  trailsList,
  isFetchingTrailsList,
  handleGoToGuideView,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedTrailGuideId = useSelector(getSelectedTrailGuideIdSelector);
  const distanceUnit = useSelector(getDistanceUnitSelector);
  const itemsInCart = useSelector<AppState, CartItemsHashmapCollection>(
    selectCartItemsHashmapCollection
  );
  const itemsBeingAddedToCart = useSelector<
    AppState,
    CartItemsHashmapCollection
  >(selectItemsBeingAddedToCart);
  const [currentId, setCurrentId] = useState<string>('');
  const slider: MutableRefObject<CarouselRef | null> = useRef(null);
  const indexedIds: MutableRefObject<string[]> = useRef([]);

  indexedIds.current = trailsList.map(({ guideId }) => guideId);

  const handleSetSelectedTrailGuideId = useCallback(
    (guideId: string) => {
      setCurrentId(guideId);
      dispatch(actions.setSelectedTrailGuideId(guideId));
    },
    [dispatch]
  );

  const handleGoToRequiredSlide = useCallback((selectedId: string) => {
    const guideIndex = indexedIds.current?.findIndex(
      (guideId: string) => guideId === selectedId
    );

    slider?.current?.goTo(guideIndex, true);
  }, []);

  const handleBeforeChange = (fromSlideIndex: number, toSlideIndex: number) => {
    handleSetSelectedTrailGuideId(indexedIds.current[toSlideIndex]);
  };

  const hanldeGoToGeoPage = (guideName: string) => {
    history.push(`/guides/${encodeURIComponent(guideName)}/geo`);
  };

  useEffect(() => {
    if (selectedTrailGuideId) {
      if (currentId !== selectedTrailGuideId) {
        handleGoToRequiredSlide(selectedTrailGuideId);
      }
    } else {
      handleSetSelectedTrailGuideId(indexedIds.current[0]);
    }
  }, [
    selectedTrailGuideId,
    handleGoToRequiredSlide,
    currentId,
    handleSetSelectedTrailGuideId,
  ]);

  return (
    <S.CarouselWrapper>
      <Carousel
        slidesToShow={1}
        centerMode={true}
        draggable={true}
        swipeToSlide={true}
        touchThreshold={50}
        focusOnSelect={true}
        dots={false}
        height={80}
        beforeChange={handleBeforeChange}
        ref={(ref: CarouselRef) => {
          slider.current = ref;
        }}
        centerPadding="50px"
        lazyLoad="ondemand"
        infinite={false}
      >
        {trailsList.map(
          ({
            guideId,
            shopifyVariantId,
            guideName,
            guidePrice,
            guideLength,
            guidePromoText,
            guideIconImage,
            guideIsFree,
            isPurchased,
            guideHasOpenButton,
            guideIsPurchaseable,
            parentGuides,
          }) => (
            <S.ItemWrapper key={guideId}>
              <TrailCard
                size="small"
                shape="bumpy"
                guideId={guideId}
                shopifyVariantId={shopifyVariantId}
                guideName={guideName}
                guidePromoText={guidePromoText}
                guideLength={{
                  distance: guideLength[distanceUnit.name],
                  shortName: distanceUnit.shortName,
                }}
                itemsInCart={itemsInCart}
                itemsBeingAddedToCart={itemsBeingAddedToCart}
                guidePrice={guidePrice?.final}
                guideIconImage={guideIconImage}
                guideIsFree={guideIsFree}
                isPurchased={isPurchased}
                parentGuides={parentGuides}
                guideHasOpenButton={guideHasOpenButton}
                onOpen={hanldeGoToGeoPage}
                guideIsPurchaseable={guideIsPurchaseable}
              />
            </S.ItemWrapper>
          )
        )}
        {isFetchingTrailsList && (
          <S.ItemWrapper>
            <LoadedCard size="small" />
          </S.ItemWrapper>
        )}
      </Carousel>
    </S.CarouselWrapper>
  );
};

export const TrailsCorousel = memo(TrailsCorouselComponent, isEqual);
