import { fetcher } from '../../configure'
import { ShopifyCartDto } from './dto/cart.dto'

type ID = string
export type SetShopifyCartBuyerIdentityRequest = {
	cartId: ID | 'testing'
	countryCode: string | null
	parseUserId?: ID,
	avantLinkHash?: string,
}

export type SetShopifyCartBuyerIdentityResponse = ShopifyCartDto

export type SetShopifyCartBuyerIdentityEndpoint = (
	request: SetShopifyCartBuyerIdentityRequest
) => Promise<SetShopifyCartBuyerIdentityResponse>

export const setShopifyCartBuyerIdentity: SetShopifyCartBuyerIdentityEndpoint = async (request) => {
	console.log("set-shopify-cart-buyer-identity : ", request);

	return await fetcher({
		method: 'put',
		path: `/shopify/cart/identity`,
		body: request,
	})
}
