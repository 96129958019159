import { memo, useCallback } from 'react';
import isEqual from 'lodash.isequal';
import { PointCard } from '../PointCard/PointCard';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { S } from './PointsMenu.styles';
import { PointsMenuProps } from './PointsMenu.interface';
import { WaypointLocalizator } from 'services/LocalizationManager/WaypointLocalizator'

const PointsMenuComponent: React.FC<PointsMenuProps> = ({
  waypoints,
  primaryDirection,
  onPointClick,
}) => {
  const Row = useCallback(
    ({ data: { waypoints }, index, style }) => {
      let waypoint = waypoints[index]
      return (
        <div
          style={style}
          onClick={() => onPointClick && onPointClick(waypoint.id)}
        >
          <PointCard
            {...waypoint}
            title={WaypointLocalizator.getTitle(waypoint)}
            primaryDirection={primaryDirection}
            iconType={waypoint.waypointIconSet[0]}
          />
        </div>
      );
    },
    [onPointClick, primaryDirection]
  );

  return (
    <S.Container>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={height}
            itemCount={waypoints.length}
            itemSize={80}
            width={width}
            itemData={{ waypoints }}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </S.Container>
  );
};

export const PointsMenu = memo(PointsMenuComponent, isEqual);
