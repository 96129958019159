import { takeLatest, put, call } from 'redux-saga/effects'
import { Awaited } from 'global_types';
import { actions } from 'store/GuideUse/actions'
import { commentsStore } from 'store/GuideUse/CommentsStore'
import { AnyAction } from 'redux'
import { getGuideGeo, GetGuideEndpoint } from 'atlasguides-web-common/src/functions/guides/get-guide-geo';
import { getComments, GetCommentsEndpoint } from 'atlasguides-web-common/src/functions/comments/get-comments'
import { Comment } from 'atlasguides-web-common/src/functions/comments/dto/comment.dto';
import { GetGuideWaypointsEndpoint, GetGuideWaypointsResponse, getGuideWaypoints } from 'atlasguides-web-common/src/functions/guides/get-guide-waypoints';


function* getGuideDataSaga({payload: guideId}: AnyAction): Generator<unknown, void, Awaited<ReturnType<GetGuideEndpoint & GetGuideWaypointsEndpoint>>> {
  try {
    const request = { guideId }
    const res = yield call<GetGuideEndpoint>(getGuideGeo, request)
    const waypointsRes = yield call<GetGuideWaypointsEndpoint>(getGuideWaypoints, request) as unknown as GetGuideWaypointsResponse
    yield put(actions.getGuideGeoDataResponse({...res, 
        waypoints: (waypointsRes as unknown as GetGuideWaypointsResponse).waypoints}))
  } catch (error) {
    // yield put(actions.getAllTrailsListResponseFail(error.message))
    // console.log('failed')
  }
}

function* getWaypointCommentsSaga({payload: waypointId}: AnyAction): Generator<unknown, void, Awaited<ReturnType<GetCommentsEndpoint>>> {
  try {
    // Review cache to remove not actual items
    commentsStore.review();

    // Get from Cache
    if (commentsStore.hasCommentsForWaypoint(waypointId)){
      //console.log(`getWaypointCommentsSaga: '${waypointId}' read from cache`)
      yield put(actions.getWaypointCommentsResponse(commentsStore.getComments(waypointId)))
    }
    else{
      const request = { waypointId }

      //console.log(`getWaypointCommentsSaga: '${waypointId}' loading...`)
      const res = yield call(getComments, request)

      // Sort
      if (res.comments && res.comments.length > 0){
        res.comments.sort((a: Comment, b: Comment) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
        );
      }

      //console.log(`getWaypointCommentsSaga: '${waypointId}' loaded and added to cache: ` + JSON.stringify(res))
      commentsStore.add(res);

      yield put(actions.getWaypointCommentsResponse(commentsStore.getComments(waypointId)))
    }
  } catch (error) {
    // yield put(actions.getAllTrailsListResponseFail(error.message))
    console.error(`getWaypointCommentsSaga: failed to get comments because of the following error:\n${error}`
    );    
  }
}

export default function* saga(): Generator {
  yield takeLatest('@/GET_GUIDE_DATA_REQUEST', getGuideDataSaga)
  yield takeLatest('@/GET_WAYPOINT_COMMENTS_REQUEST', getWaypointCommentsSaga)
}
