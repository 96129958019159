import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';

export const S = {
  Container: styled.div`
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: visible;
    background: ${({ theme }) => theme.pointDetails.colors.backgroundContainer};
    z-index: 1002;
  `,
  DetailSection: styled.div<{ withoutBorder?: boolean }>`
    padding: 10px;
    display: flex;
    flex-direction: column;
    ${({ theme, withoutBorder }) =>
      !withoutBorder &&
      `border-top: 1px solid ${theme.pointDetails.colors.borderDetailSection}`}
  `,
  PointName: styled.span`
    font-weight: bold;
    font-size: 16px;
  `,
  ShortInfo: styled.ul`
    margin: 0;
    padding: 0;
  `,
  Li: styled.li`
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: 18px;
    }

    &:not(:last-child)::after {
      margin-right: 12px;
      position: absolute;
      top: 10px;
      right: -22px;
      display: inline-block;
      content: '';
      height: 3px;
      width: 3px;
      border-radius: 50px;
      background: ${({ theme }) =>
        theme.pointDetails.colors.backgroundSortInfoDivider};
    }
  `,
  Description: styled.span`
    white-space: pre-wrap;
    word-break: break-word;
  `,
  DistanceToPointWrapper: styled.div`
    margin-bottom: 30px;
  `,
  CloseButton: styled(CloseCircleFilled)`
    position: absolute;
    right: 22px;
    top: 20px;
    font-size: 22px;
    color: ${({ theme }) => theme.pointDetails.colors.backgroundCloseButton};
    cursor: pointer;
  `,
  WaypointIconSet: styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 5px;
  `,
  Icon: styled.img.attrs(({ src }) => ({
    src,
  }))`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 50px;
    border: 3px solid ${({ theme }) => theme.pointDetails.colors.borderIcon};
    border-radius: 5px;

    & svg {
      width: 42px;
      height: 42px;
    }

    & svg g {
      fill: ${({ color }) => `#${color}`};
      width: 100%;
      height: 100%;
    }

    & svg path {
      width: 100%;
      height: 100%;
    }
  `,
  HeaderContainer: styled.div`
    padding-right: 50px;
    display: flex;
  `,
  BackButtonContainer: styled.div`
    padding-left: 20px;
    display: flex;
    margin-right: 10px;
  `,
  ShowOnSection: styled.span`
    color: ${({ theme }) => theme.pointDetails.colors.textShowOnSection};
    cursor: pointer;
  `,
  SkeletonListContainer: styled.div`
    margin-left: 10px;
  `,
  CommentsContainer: styled.div`
    margin-left: 10px;
  `,
};
