import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';
import { FilterView } from 'views/Filter/Filter';
import { ModalDialog } from 'shared/ModalDialog/ModalDialog';
import { actions } from 'store/TrailsStore/actions';
import {
  getGuidesFilterParamsSelector,
  getAllTrailsListWithChildrenSelector,
  getAllTrailsListLengthSelector,
  getIsSearchedStateSelector,
  getSearchedValue,
  getActiveSelectedFiltersSelector,
  getGuidesSelector,
} from 'store/TrailsStore/selectors';
import { searchByParams } from 'views/TrailsStore/GuidesSearchInput/GuidesSearchInput';

import { PropType } from 'commonTypes/types';
import {
  FilteredGuides,
  SelectedGuideFilters,
  Guides,
  Guide,
} from 'commonTypes/TrailsStore/guides';
import { FilterContainerProps, SetParams } from './FilterContainer.interface';

export const getSelectedFiltersListLength = (
  selectedFilters: any,
  filterParams: any
) => {
  const selectedFiltersKeys = Object.keys(selectedFilters);

  if (!selectedFiltersKeys.length) {
    return 0;
  }

  return selectedFiltersKeys.reduce((accum, param) => {
    if (param === 'guideName') {
      return accum;
    }

    if (Array.isArray(selectedFilters[param])) {
      return accum + selectedFilters[param].length;
    }

    if (
      typeof selectedFilters[param] === 'object' &&
      selectedFilters[param] !== null &&
      !Array.isArray(selectedFilters[param])
    ) {
      return accum + Object.keys(selectedFilters[param]).length;
    }

    return selectedFilters[param] === filterParams[param] ? accum : accum + 1;
  }, 0);
};

const FilterContainerComponent: React.FC<FilterContainerProps> = ({
  isFetchingTrailsList,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const filterParams = useSelector(getGuidesFilterParamsSelector);
  const isSearchedState = useSelector(getIsSearchedStateSelector);
  const allTrailsListLength = useSelector(getAllTrailsListLengthSelector);
  const allTrailsList = useSelector(getAllTrailsListWithChildrenSelector);
  const searchedValue = useSelector(getSearchedValue);
  const activeSelectedFilters = useSelector(getActiveSelectedFiltersSelector);
  const filteredGuidesFromState = useSelector(getGuidesSelector);

  const [filterParamsState, setFilterParamsState] = useState<any>({
    minLength: 0,
    maxLength: 100,
    activityType: [],
    region: [],
    environment: [],
    other: [],
  });
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [filteredGuides, setFilteredGuides] = useState<FilteredGuides>([]);
  const [filteredGuidesLength, setFilteredGuidesLength] = useState<number>(0);
  const [expandedSections, setExpandedSections] = useState<string | string[]>(
    []
  );

  const handleSetSelectedFilters = ({ sectionName, value }: SetParams) => {
    setSelectedFilters((prev: SelectedGuideFilters) => ({
      ...prev,
      [sectionName]: value,
    }));
  };

  const handleCheckSelectedFiltersLength = useCallback(() => {
    return getSelectedFiltersListLength(selectedFilters, filterParamsState);
  }, [selectedFilters, filterParamsState]);

  const onApply = useCallback(() => {
    if (!handleCheckSelectedFiltersLength() && filteredGuidesLength > 0) {
      onClose && onClose();
      return;
    }

    if (filteredGuidesLength > 0) {
      dispatch(actions.setActiveSelectedFilters(selectedFilters));
      onClose && onClose();
    } else {
      ModalDialog('No filters have been found', 'Nothing has been found');
    }
  }, [
    dispatch,
    filteredGuidesLength,
    onClose,
    selectedFilters,
    handleCheckSelectedFiltersLength,
  ]);

  const onClearAllFilters = () => {
    setSelectedFilters({});
    dispatch(actions.setClearedFilters());
    dispatch(actions.setIsFilteredState(false));
    setFilteredGuides([]);
  };

  const handleClose = () => {
    setSelectedFilters(activeSelectedFilters);
    onClose && onClose();
  };

  const handleSetExpandedSections = (key: string | string[]) => {
    sessionStorage.setItem(
      'expandedFilterSections',
      Array.isArray(key) ? key.join(';') : `${key}`
    );
    setExpandedSections(key);
  };

  useEffect(() => {
    const expanded = sessionStorage.getItem('expandedFilterSections');
    expanded && setExpandedSections(expanded.split(';'));
  }, []);

  // Initialize selectedFilters from activeSelectedFilters on open
  // useEffect(() => {
  //   if (Object.keys(activeSelectedFilters).length !== 0 && Object.keys(selectedFilters).length === 0){
  //     setSelectedFilters(activeSelectedFilters);
  //     setFilteredGuides(filteredGuidesFromState)
  //   }
  // }, [activeSelectedFilters, selectedFilters, filteredGuidesFromState]);

  if (Object.keys(activeSelectedFilters).length !== 0 && Object.keys(selectedFilters).length === 0){
    setSelectedFilters(activeSelectedFilters);
    setFilteredGuides(filteredGuidesFromState)
  }

  const handleFilterByParams = (
    guides: Guides,
    params: SelectedGuideFilters
  ) => {
    const paramKeys: string[] = Object.keys(params);

    if (!paramKeys.length) {
      return guides;
    }

    const filterByParam = (
      arr: Guides | FilteredGuides,
      param: string,
      params: SelectedGuideFilters
    ): FilteredGuides => {
      switch (param) {
        case 'minLength':
          return arr.filter(
            (guide: Guide) => (params[param] || 0) < guide.guideLength.meters
          );
        case 'maxLength':
          return arr.filter(
            (guide: Guide) => (params[param] || 0) > guide.guideLength.meters
          );
        case 'environment':
        case 'activityType':
        case 'region':
        case 'other':
          return params[param]?.length
            ? arr.filter((guide: Guide) =>
                params[param]?.some((tag: string) =>
                  guide.guideTags?.includes(tag)
                )
              )
            : arr;
      }
      return arr;
    };

    return paramKeys.reduce(
      (accum: Guides | FilteredGuides, param: string): FilteredGuides => {
        return filterByParam(accum, param, params);
      },
      guides
    );
  };

  const calculateResultListLength = useCallback(() => {
    const filteredList = handleFilterByParams(filteredGuides, selectedFilters);

    const searchedList = isSearchedState
      ? searchByParams(filteredList, searchedValue)
      : filteredList;

    setFilteredGuidesLength(searchedList.length);
  }, [filteredGuides, isSearchedState, searchedValue, selectedFilters]);

  const calculateFilteredGuides = useCallback(() => {
    const filteredList = handleFilterByParams(filteredGuides, selectedFilters);

    dispatch(actions.setFilteredGuides(filteredList));
  }, [dispatch, filteredGuides, selectedFilters]);

  useEffect(() => {
    filteredGuides.length || setFilteredGuides(allTrailsList);
  }, [filteredGuides, allTrailsList]);

  useEffect(() => {
    calculateResultListLength();

    if (!!handleCheckSelectedFiltersLength()) {
      calculateFilteredGuides();
    }
  }, [
    calculateFilteredGuides,
    calculateResultListLength,
    handleCheckSelectedFiltersLength,
  ]);

  useEffect(() => {
    dispatch(actions.setIsFilteredState(!!handleCheckSelectedFiltersLength()));
  }, [dispatch, handleCheckSelectedFiltersLength]);

  useEffect(() => {
    const { minLength, maxLength, activityType, region, environment, other } =
      filterParams;

    setFilterParamsState((prev: any) => ({
      ...prev,
      minLength: minLength,
      maxLength: maxLength,
      activityType: activityType?.map(
        (item: PropType<SelectedGuideFilters, 'activityType'>) => ({
          tag: item,
          checked: false,
        })
      ),
      region: region?.map((item: PropType<SelectedGuideFilters, 'region'>) => ({
        label: item,
        value: item,
      })),
      environment: environment?.map(
        (item: PropType<SelectedGuideFilters, 'environment'>) => ({
          label: item,
          value: item,
          checked: true,
        })
      ),
      other: other?.map((item: PropType<SelectedGuideFilters, 'other'>) => ({
        tag: item,
        checked: false,
      })),
    }));
  }, [filterParams]);

  return (
    <>
      {isOpen ? (
        <FilterView
          filterParamsState={filterParamsState}
          selectedFilters={selectedFilters}
          handleSetSelectedFilters={handleSetSelectedFilters}
          isFetchingTrailsList={isFetchingTrailsList}
          allTrailsListLength={allTrailsListLength}
          filteredGuidesLength={filteredGuidesLength}
          isSearchedState={isSearchedState}
          expandedSections={expandedSections}
          handleSetExpandedSections={handleSetExpandedSections}
          onClearAllFilters={onClearAllFilters}
          onApply={onApply}
          onClose={handleClose}
          getSelectedFiltersListLength={getSelectedFiltersListLength}
        />
      ) : null}
    </>
  );
};

export const FilterContainer = memo(FilterContainerComponent, isEqual);
