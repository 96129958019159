import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  GetUpdatedProfileRequestPayload,
  GetUserProfileRequestPayload,
  userActions,
} from './index';
import { PayloadAction } from '@reduxjs/toolkit';
import { Awaited } from '../../global_types';
import {
  getUserProfile,
  GetUserProfileEndpoint,
} from '../../atlasguides-web-common/src/functions/users/get-user-profile';
import {
  updateUserProfile,
  UpdateUserProfileEndpoint,
} from '../../atlasguides-web-common/src/functions/users/update-user-profile-info';
import {
  updateUserProfilePhoto,
  UpdateUserProfilePhotoEndpoint,
  UpdateUserProfilePhotoRequest,
} from '../../atlasguides-web-common/src/functions/users/update-user-profile-photo';
import {
  updateUserCoverPhoto,
  UpdateUserCoverPhotoEndpoint,
  UpdateUserCoverPhotoRequest,
} from '../../atlasguides-web-common/src/functions/users/update-user-cover-photo';
import {
  actions,
  selectShopifyCart,
} from '../CartStore';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { getResultCountryCode } from 'utils/geoUtils';
import { setShopifyCartBuyerIdentity, SetShopifyCartBuyerIdentityEndpoint, SetShopifyCartBuyerIdentityRequest, SetShopifyCartBuyerIdentityResponse } from 'atlasguides-web-common/src/functions/cart/set-shopify-cart-buyer-identity';

const USER_ERROR_PREFIX = 'UserError';

function* getProfile({
  payload,
}: PayloadAction<GetUserProfileRequestPayload>): Generator<
  any,
  void,
  Awaited<ReturnType<GetUserProfileEndpoint>>
> {
  const { userId } = payload;

  try {
    const request = { username: userId };
    const result = yield call<GetUserProfileEndpoint>(getUserProfile, request);
    if (!result.success) {
      throw new Error(
        'FrontEnd: back end failed to give a user profile for some reason.'
      );
    }
    if (result.userProfile) {
      yield put(userActions.getUserProfileResponse(result.userProfile as any));
      console.log("got info: ", result.userProfile.primaryInfo.parseUserId);
      localStorage.setItem('parseUserId', result.userProfile.primaryInfo.parseUserId);
      //make call to update shopify cart here
      const shopifyCart: any = yield select<typeof selectShopifyCart>(selectShopifyCart);
      const avantLinkHash = LocalStorageManager.getInstance().getItemWithExpiry('avantLinkHash')

      if(shopifyCart.id){

        var resCountryCode = (yield call(getResultCountryCode)) as unknown as string 

        // Set Shopify Buyer Identity
        const setShopifyCartBuyerIdentityRequest: SetShopifyCartBuyerIdentityRequest = { 
          parseUserId: result.userProfile.primaryInfo.parseUserId, 
          cartId: shopifyCart.id, 
          avantLinkHash, 
          countryCode: resCountryCode 
        }

        const setShopifyCartBuyerIdentityResponse: SetShopifyCartBuyerIdentityResponse = (yield call<SetShopifyCartBuyerIdentityEndpoint>(setShopifyCartBuyerIdentity, setShopifyCartBuyerIdentityRequest)) as unknown as SetShopifyCartBuyerIdentityResponse
        if (setShopifyCartBuyerIdentityResponse){
          yield put(actions.setShopifyCart(setShopifyCartBuyerIdentityResponse));
        }
      }
    }
  } 
  catch (error) {
    yield put(userActions.getUserProfileFail());
    console.error(
      `${USER_ERROR_PREFIX}: failed to add an item because of the following error;\n${error}`
    );
  }
}

function* getUpdatedProfileSaga({
  payload,
}: PayloadAction<GetUpdatedProfileRequestPayload>): Generator<
  any,
  void,
  Awaited<ReturnType<UpdateUserProfileEndpoint>>
> {
  const { onError, onSuccess } = payload;
  try {
    const request = { userInfo: payload };
    const result = yield call<UpdateUserProfileEndpoint>(
      updateUserProfile,
      request
    );

    if (result.updatedUserInfo) {
      yield put(userActions.getUpdatedProfileResponse(result.updatedUserInfo));
      onSuccess && onSuccess();
    }
  } catch (error) {
    yield put(userActions.getUserProfileFail());
    onError && onError();
    console.error(
      `${USER_ERROR_PREFIX}: failed to add an item because of the following error;\n${error}`
    );
  }
}

function* updateUserProfileImageSaga({
  payload,
}: PayloadAction<UpdateUserProfilePhotoRequest>): Generator<
  any,
  void,
  Awaited<ReturnType<UpdateUserProfilePhotoEndpoint>>
> {
  try {
    const request = { ...payload };

    const result = yield call<UpdateUserProfilePhotoEndpoint>(
      updateUserProfilePhoto,
      request
    );

    if (!result.success) {
      yield put(userActions.updateUserImageProfileFail());
      throw new Error(
        'FrontEnd: back end failed to give a user profile for some reason.'
      );
    }

    yield put(
      userActions.updateUserImageProfile({
        imageUrl: payload.base64EncodedPhoto,
      })
    );
  } catch (error) {
    yield put(userActions.updateUserImageProfileFail());
    console.error(
      `${USER_ERROR_PREFIX}: failed to add an item because of the following error;\n${error}`
    );
  }
}

function* updateUserCoverImageSaga({
  payload,
}: PayloadAction<UpdateUserCoverPhotoRequest>): Generator<
  any,
  void,
  Awaited<ReturnType<UpdateUserCoverPhotoEndpoint>>
> {
  try {
    const request = { ...payload };

    const result = yield call<UpdateUserCoverPhotoEndpoint>(
      updateUserCoverPhoto,
      request
    );

    if (!result.success) {
      yield put(userActions.updateUserCoverImageFail());
      throw new Error(
        'FrontEnd: back end failed to give a user profile for some reason.'
      );
    }

    yield put(
      userActions.updateUserCoverImage({
        imageUrl: payload.base64EncodedPhoto,
      })
    );
  } catch (error) {
    yield put(userActions.updateUserCoverImageFail());
    console.error(
      `${USER_ERROR_PREFIX}: failed to add an item because of the following error;\n${error}`
    );
  }
}

export function* userStoreSaga(): Generator {
  yield takeLatest(userActions.getUserProfileRequest.type, getProfile);
  yield takeLatest(
    userActions.getUpdatedProfileRequest.type,
    getUpdatedProfileSaga
  );
  yield takeLatest(
    userActions.updateUserImageProfileRequest.type,
    updateUserProfileImageSaga
  );
  yield takeLatest(
    userActions.updateUserCoverImageRequest.type,
    updateUserCoverImageSaga
  );
}
