import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import {
  distanceUnitName,
  metersInDistanceUnit,
  shortDistanceUnitName,
} from 'constants/common/distanceUnits';
import {
  DistanceUnit,
  ElevationUnit,
} from 'commonTypes/CommonStore/distanceUnits';
import { actions } from 'store/Common/actions';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

export const useSettingsUtils = () => {
    const dispatch = useDispatch()

    const localStorage = LocalStorageManager.getInstance();

    const resActions = useMemo(() => ({

        validateSettings() {
          let selectedCountryCode: string | null = localStorage.getItem('shopifyCountry');
          const geoCountryCode : string | null = localStorage.getItem('geoCountryCode'); 
          let actualDistanceUnit: DistanceUnit | null = localStorage.getItem('distanceUnit');
          let actualElevationUnit: ElevationUnit | null = localStorage.getItem('elevationUnit');
          const clockwiseDirectionTrails: string[] = localStorage.getItem('clockwiseDirectionTrails')?.split(';') || [];

          if (!selectedCountryCode) {
            selectedCountryCode = geoCountryCode;
          }
    
          // Set metric system if not US and not CA 
          if (actualDistanceUnit == null || actualElevationUnit == null){
              if (selectedCountryCode === 'US' || selectedCountryCode === 'GB'){
                  actualDistanceUnit = distanceUnitName.MI;
                  actualElevationUnit = distanceUnitName.FT;
              }
              else{
                  actualDistanceUnit = distanceUnitName.KM;
                  actualElevationUnit = distanceUnitName.M;
              }

              localStorage.setItem('autoDistanceUnit', actualDistanceUnit);
              localStorage.setItem('autoElevationUnit', actualElevationUnit);              
          }
    
          dispatch(
            actions.setDistanceUnit({
                name: actualDistanceUnit,
                valueInMeters: metersInDistanceUnit[actualDistanceUnit],
                shortName: shortDistanceUnitName[actualDistanceUnit],
            })
          );

          dispatch(
            actions.setElevationUnit({
                name: actualElevationUnit,
                valueInMeters: metersInDistanceUnit[actualElevationUnit],
                shortName: shortDistanceUnitName[actualElevationUnit],
            })
          );

          dispatch(actions.setClockwiseDirectionTrails(clockwiseDirectionTrails));
        },

        applyDistanceUnitChanging(isMetric: boolean) {
            let actualDistanceUnit = distanceUnitName.MI;
            let actualElevationUnit = distanceUnitName.FT;
            if (isMetric){
                actualDistanceUnit = distanceUnitName.KM;
                actualElevationUnit = distanceUnitName.M;
            }

            localStorage.setItem('distanceUnit', actualDistanceUnit);
            localStorage.setItem('elevationUnit', actualElevationUnit);
            localStorage.removeItem('autoDistanceUnit');
            localStorage.removeItem('autoElevationUnit');

            dispatch(
                actions.setDistanceUnit({
                    name: actualDistanceUnit,
                    valueInMeters: metersInDistanceUnit[actualDistanceUnit],
                    shortName: shortDistanceUnitName[actualDistanceUnit],
                })
            );
  
            dispatch(
                actions.setElevationUnit({
                    name: actualElevationUnit,
                    valueInMeters: metersInDistanceUnit[actualElevationUnit],
                    shortName: shortDistanceUnitName[actualElevationUnit],
                })
            );
        },

        onCountrySelected(countryCode: string) {
            if (onCountrySelectedFunc(countryCode)){

                let actualDistanceUnit: DistanceUnit | null = getActualDistanceUnit()
                let actualElevationUnit: ElevationUnit | null= getActualElevationUnit()
            
                if (actualDistanceUnit){
                    dispatch(
                        actions.setDistanceUnit({
                            name: actualDistanceUnit,
                            valueInMeters: metersInDistanceUnit[actualDistanceUnit],
                            shortName: shortDistanceUnitName[actualDistanceUnit],
                        })
                    );
                }
    
                if (actualElevationUnit){
                    dispatch(
                        actions.setElevationUnit({
                            name: actualElevationUnit,
                            valueInMeters: metersInDistanceUnit[actualElevationUnit],
                            shortName: shortDistanceUnitName[actualElevationUnit],
                        })
                    );
                }
            }
        }

    }), [dispatch, localStorage])

    return resActions
}

export function getActualDistanceUnit() : DistanceUnit | null{
    let actualDistanceUnit: DistanceUnit | null = localStorage.getItem('distanceUnit') as DistanceUnit | null;
    if (actualDistanceUnit == null){
        actualDistanceUnit = localStorage.getItem('autoDistanceUnit') as DistanceUnit | null;
    }
    return actualDistanceUnit
}

export function getActualElevationUnit() : ElevationUnit | null{
    let actualElevationUnit: ElevationUnit | null = localStorage.getItem('elevationUnit') as ElevationUnit | null;
    if (actualElevationUnit == null){
        actualElevationUnit = localStorage.getItem('autoElevationUnit') as ElevationUnit | null;
    }
    return actualElevationUnit
}

export function onCountrySelectedFunc(countryCode: string | null) {

    if (!countryCode){
        return false
    }

    let geoCountryCode = localStorage.getItem('geoCountryCode'); 
    
    if (countryCode === geoCountryCode){
        localStorage.removeItem('shopifyCountry');
    }
    else{
        localStorage.setItem('shopifyCountry', countryCode as string);
    }

    let actualDistanceUnit: DistanceUnit | null = localStorage.getItem('distanceUnit') as DistanceUnit | null;
    let actualElevationUnit: ElevationUnit | null = localStorage.getItem('elevationUnit') as ElevationUnit | null;

    // Set metric system if not US and not CA 
    if (actualDistanceUnit == null || actualElevationUnit == null){
        if (countryCode === 'US' || countryCode === 'GB'){
            actualDistanceUnit = distanceUnitName.MI;
            actualElevationUnit = distanceUnitName.FT;
        }
        else{
            actualDistanceUnit = distanceUnitName.KM;
            actualElevationUnit = distanceUnitName.M;
        }

        localStorage.setItem('autoDistanceUnit', actualDistanceUnit);
        localStorage.setItem('autoElevationUnit', actualElevationUnit);

        return true
    }
    return false
}
