import styled from 'styled-components';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const Reused = {
  SideControls: styled.div`
    position: absolute;
    color: ${({ theme }) => theme.distanceToPoint.colors.textSideControls};
    display: flex;
    align-items: center;
  `,
};

export const S = {
  Container: styled.div`
    min-height: 45px;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    align-items: center;
  `,
  LeftSideControls: styled(Reused.SideControls)`
    left: 0;
  `,
  RightSideControls: styled(Reused.SideControls)`
    right: 0;
  `,
  Distance: styled.div`
    font-size: 18px;
  `,
  Icon: styled.img.attrs(({ src }) => ({
    src,
  }))
  ` margin: 0 auto;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  RightOutlined: styled(RightOutlined)`
    margin-left: 20px;
    font-size: 20px;
  `,
  LeftOutlined: styled(LeftOutlined)`
    margin-right: 20px;
    font-size: 20px;
  `,
};
