import { put, call, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Awaited } from 'global_types';
import {
  actions,
  CartItemFrontend,
  CreateShopifyCartRequestPayload,
  GetShopifyCartRequestPayload,
  RequestSetCartBuyerIdentityPayload,
  selectShopifyCart,
} from '.';
import {
  AddCartEndpoint,
  addCartItem,
  AddCartItemRequest,
  AddCartItemResponse,
} from 'atlasguides-web-common/src/functions/cart/add-cart-item';
import {
  RemoveShopifyLineItemEndpoint,
  removeShopifyLineItem,
} from 'atlasguides-web-common/src/functions/cart/remove-shopify-line-item';

import { ShopifyCartDto } from '../../atlasguides-web-common/src/functions/cart/dto/cart.dto';

import {
  createShopifyCart,
  CreateShopifyCartEndpoint,
} from 'atlasguides-web-common/src/functions/cart/create-shopify-cart';

import {
  getShopifyCart,
  GetShopifyCartEndpoint,
  GetShopifyCartRequest,
} from 'atlasguides-web-common/src/functions/cart/get-shopify-cart';

import ReactPixel from 'react-facebook-pixel';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { getResultCountryCode } from 'utils/geoUtils';
import { setShopifyCartBuyerIdentity, SetShopifyCartBuyerIdentityEndpoint, SetShopifyCartBuyerIdentityRequest, SetShopifyCartBuyerIdentityResponse } from 'atlasguides-web-common/src/functions/cart/set-shopify-cart-buyer-identity';

const CART_ERROR_PREFIX = 'CartError';

function* getShopifyCartFunc({
  payload,
}: PayloadAction<GetShopifyCartRequestPayload>): Generator<
  any,
  void,
  Awaited<ReturnType<GetShopifyCartEndpoint>>
> {
  try {
    let cart: ShopifyCartDto;
    const localStorage = LocalStorageManager.getInstance();

    if (payload.id) {
      // Get

      var resCountryCode = (yield call(getResultCountryCode)) as unknown as string 

      const request: GetShopifyCartRequest = {
        id: payload.id,
        countryCode: resCountryCode
      }
      cart = yield call<GetShopifyCartEndpoint>(getShopifyCart, request);
      if (!cart || !cart.id) {
        //Create
        cart = yield call<CreateShopifyCartEndpoint>(createShopifyCart);
      }
    } else {
      //Create
      cart = yield call<CreateShopifyCartEndpoint>(createShopifyCart);
  }

    if (cart && cart.id) {
      yield put(actions.setShopifyCart(cart));
      if (!payload.id || payload.id !== cart.id) {
        //as of 11/30/22 shopify cart expiration is 14 days
        //set cartId cookie expiration for 7 days
        localStorage.setItemWithExpiry('shopifyCartId', cart.id, 7*24*60*60*1000);
      }
    }
  } catch (error) {
    console.error(
      `${CART_ERROR_PREFIX}: failed to get shopify cart because of the following error;\n${error}`
    );
    payload.onError && payload.onError(error);
  } finally {
    if (payload.showLoadingProgress) yield put(actions.setCartIsLoading(false));
  }
}

function* clearCart({
  payload,
}: PayloadAction<CreateShopifyCartRequestPayload>): Generator<
  any,
  void,
  Awaited<ReturnType<CreateShopifyCartEndpoint>>
> {
  //remove old local storage and clear redux
  localStorage.removeItem('shopifyCartId');
  yield put(
    actions.setShopifyCart({
      id: '',
      lineItems: [],
      itemsSubtotal: 0,
      order: {},
      total: 0,
    })
  );
  try {
    const result: ShopifyCartDto = yield call<CreateShopifyCartEndpoint>(
      createShopifyCart
    );
    const localStorage = LocalStorageManager.getInstance();

    var resCountryCode = (yield call(getResultCountryCode)) as unknown as string     

    console.log('clearCart result: ', result);
    const cartId = result.id;
    //as of 11/30/22 shopify cart expiration is 14 days
    //set cartId cookie expiration for 7 days
    localStorage.setItemWithExpiry('shopifyCartId', cartId, 7*24*60*60*1000);
    yield put(actions.setShopifyCart(result));
    const parseUserId = localStorage.getItem('parseUserId');
    const avantLinkHash =
      LocalStorageManager.getInstance().getItemWithExpiry('avantLinkHash');

    const request: SetShopifyCartBuyerIdentityRequest = {
      cartId,
      countryCode: resCountryCode,
      parseUserId,
      avantLinkHash
    }
    yield call<SetShopifyCartBuyerIdentityEndpoint>(setShopifyCartBuyerIdentity, request);      

  } catch (error) {
    console.error(
      `${CART_ERROR_PREFIX}: failed to create shopify cart because of the following error;\n${error}`
    );
    payload.onError && payload.onError(error);
  }
}


function* setCartBuyerIdentity({
  payload,
}: PayloadAction<RequestSetCartBuyerIdentityPayload>): Generator<
  any,
  void,
  Awaited<ReturnType<SetShopifyCartBuyerIdentityEndpoint>>
> {
  yield put(actions.setCartIsLoading(true));

  //Get user country code
  try {

    const localStorage = LocalStorageManager.getInstance();

    var resCountryCode = (yield call(getResultCountryCode)) as unknown as string 
      if (resCountryCode){
      // Set Shopify Cart BuyerIdentity
      let shopifyCartId = localStorage.getItemWithExpiry('shopifyCartId');

      if (!shopifyCartId){
        const shopifyCart = yield select<typeof selectShopifyCart>(selectShopifyCart);
        if (shopifyCart){
          shopifyCartId = shopifyCart.id;
        }
      }

      const parseUserId = localStorage.getItem('parseUserId');
      const avantLinkHash =
        LocalStorageManager.getInstance().getItemWithExpiry('avantLinkHash');

      const request: SetShopifyCartBuyerIdentityRequest = {
        cartId: shopifyCartId,
        countryCode: resCountryCode,
        parseUserId,
        avantLinkHash
      }

      const result: SetShopifyCartBuyerIdentityResponse = yield call<SetShopifyCartBuyerIdentityEndpoint>
        (setShopifyCartBuyerIdentity, request);

      if (result){
        yield put(actions.setShopifyCart(result));
      }
    }
  } 
  catch (error) {
    console.error(
      `${CART_ERROR_PREFIX}: failed to set Buyer Identity because of the following error;\n${error}`
    );
  } 
  finally {
    yield put(actions.setCartIsLoading(false));
    payload.onDone && payload.onDone()
  }
}

function* addItem({
  payload,
}: PayloadAction<CartItemFrontend>): Generator<
  any,
  void,
  Awaited<ReturnType<AddCartEndpoint>>
> {

  var resCountryCode = (yield call(getResultCountryCode)) as unknown as string   

  yield put(actions.setIsAddingItem(payload));
  const shopifyCart = yield select<typeof selectShopifyCart>(selectShopifyCart);
  // console.log("addItem shopifyCart: ", shopifyCart);
  const shopifyCartId = shopifyCart.id;
  // console.log("addItem shopifyCartId: ", shopifyCartId);

  // console.log("ADD ITEM shopifyCartId: ", shopifyCartId);
  const request: AddCartItemRequest = {
    shopifyVariantId: `${payload.shopifyVariantId}`,
    shopifyCartId,
    countryCode: resCountryCode
  };
  // console.log("ADD ITEM payload: ", payload);
  // const shopRequest = { variantId: payload.shopifyVariantId}

  try {
    const result: AddCartItemResponse = yield call<AddCartEndpoint>(
      addCartItem,
      request
    );
    // console.log("Result From Add Cart Item: ", result);
    if (result) {
      // console.log("THERE WAS A RESULT HERE: ",  result);
      yield put(actions.setShopifyCart(result));
      ReactPixel.track('AddToCart', {
        content_name: payload.guideName,
        content_category: 'Guide',
        content_ids: [payload.guideId],
        content_type: 'product',
        value: payload.guidePrice,
        currency: 'USD',
      });
    }
  } catch (error) {
    console.error(
      `${CART_ERROR_PREFIX}: failed to add an item because of the following error;\n${error}`
    );
  } finally {
    yield put(actions.setItemIsAdded(payload));
  }
}

function* removeLineItemFromShopify({
  payload,
}: PayloadAction<string>): Generator<
  any,
  void,
  Awaited<ReturnType<RemoveShopifyLineItemEndpoint>>
> {
  const shopifyCart = yield select<typeof selectShopifyCart>(selectShopifyCart);
  // console.log("Typeof shopifyCart: ", shopifyCart.id);
  const shopifyCartId = shopifyCart.id;

  var resCountryCode = (yield call(getResultCountryCode)) as unknown as string 

  const request = { lineItemId: payload, shopifyCartId, countryCode: resCountryCode };
  // console.log("removeLineItemFromShopify payload: ", payload);
  try {
    // console.log("TRYING TO removeLineItemFromShopify");
    const result = yield call<RemoveShopifyLineItemEndpoint>(
      removeShopifyLineItem,
      request
    );
    // console.log("Result From RemoveShopifyLineItemEndpoint: ", result);
    yield put(actions.setShopifyCart(result));
  } catch (error) {
    console.error(
      `${CART_ERROR_PREFIX}: failed to add an item because of the following error;\n${error}`
    );
  }
}

export function* cartStoreSaga(): Generator {
  yield takeEvery(actions.requestAddItem.type, addItem);
  yield takeEvery(actions.getShopifyCart.type, getShopifyCartFunc);
  yield takeEvery(
    actions.removeLineItemFromShopify.type,
    removeLineItemFromShopify
  );
  yield takeEvery(actions.requestClearCart.type, clearCart);
  yield takeEvery(
    actions.requestSetCartBuyerIdentity.type,
    setCartBuyerIdentity
  );
}
