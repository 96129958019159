import { useViewportSize } from 'hooks/useViewportSize';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'services/AuthManager/AuthManager.react';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { App } from '../../views/App/App';
import { actions } from 'store/Common/actions';
import { actions as trailStoreActions} from 'store/TrailsStore/actions';
import { useSettingsUtils } from 'utils/settingsUtils';
import { userActions } from '../../store/UserStore';
import { actions as cartActions } from '../../store/CartStore';
import { AuthManagerService } from '../../services/AuthManager/AuthManager';
import { SetIsSignedIn } from '../../services/AuthManager/AuthManager.interfaces';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

const AppContainerComponent = () => {
  
  const params = new URLSearchParams(useLocation().search);
  const avantLinkHash = params.get('avad')

  //const subtotal = params.get('subtotal');
  //const order_number = params.get('order_number');
  //const location = useLocation();

  const dispatch = useDispatch();
  const { validateSettings } = useSettingsUtils();
  const { isMobile, isTablet } = useViewportSize();
  const { isSignedIn } = useAuth();
  const [ isDrawerVisible, setIsDrawerVisible] = useState(false);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerVisible(false);
  }, [setIsDrawerVisible]);

  const handleShowDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, [setIsDrawerVisible]);

  const handleInitCommonReducer = useCallback(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    //Setup react pixel with our facebook pixelId
    ReactPixel.init('731860360663432', undefined, options);
    ReactPixel.pageView(); // For tracking page view

    const localStorage = LocalStorageManager.getInstance();
    
    // // Try to get shopify cartid from local storage, otherwise set to null
    const shopifyCartId: string | null = localStorage.getItemWithExpiry('shopifyCartId');

    validateSettings();

    if (avantLinkHash) {
      //set expiry date for 30 days
      localStorage.setItemWithExpiry('avantLinkHash', avantLinkHash, 30*24*60*60*1000);
    }

    // if (shopifyCartId){
    //   dispatch(cartActions.requestSetCartBuyerIdentity({selectedCountryCode, shopifyCartId}));
    // }

    // If there is a shopify cart id, dispatch an action to set it in the cart state
    dispatch(cartActions.getShopifyCart({id: shopifyCartId}));

  }, [avantLinkHash, dispatch, validateSettings]);

  useEffect(() => {
    handleInitCommonReducer();
    console.log("APP CONTAINER USE EFFECT HIT");

  }, [handleInitCommonReducer]);

  useEffect(() => {
    const authManager = AuthManagerService.getInstance();
    const handleGetUserProfile: SetIsSignedIn = _isSignedIn => {
      if (_isSignedIn) {
        dispatch(userActions.getUserProfileRequest({ userId: 'mine' }));
        dispatch(trailStoreActions.getActiveSubscriptionRequest());
      } 
      else {
        dispatch(userActions.clearUserProfile());
      }
    };

    authManager.subscribeOnIsSignedIn(handleGetUserProfile);

    return () => {
      authManager.unsubscribeFromIsSignedIn(handleGetUserProfile);
    };
  }, [dispatch]);

  return (
    <App
      isUserLoggedIn={isSignedIn}
      useMobileLayout={isMobile}
      isTabletLayout={isTablet}
      isDrawerVisible={isDrawerVisible}
      handleCloseDrawer={handleCloseDrawer}
      handleShowDrawer={handleShowDrawer}
    />
  );
};

export const AppContainer = memo(AppContainerComponent);
