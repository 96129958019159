import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'store/rootReducer';
import { CartItem } from 'atlasguides-web-common/src/functions/cart/dto/cart-item.dto';
import { CartDto, ShopifyCartDto } from 'atlasguides-web-common/src/functions/cart/dto/cart.dto';
import { createSelector } from 'reselect';

export type CartItemFrontend = CartItem;

export type CartItemsList = Array<CartItemFrontend>;

export type CartItemsHashmapCollection = {
  [key: string]: CartItem;
};

export type CartTotalsFrontend = Omit<CartDto, 'items'>;
export type CartTotalsStringified = {
  [key in keyof CartTotalsFrontend]: string;
};

type CartNumericTotals = Omit<CartTotalsFrontend, 'appliedCoupon'>;
type CartStringifiedNumericTotals = {
  [key in keyof CartNumericTotals]: string;
};

export type RequestCartPayload = {
  showLoadingProgress?: boolean;
  onDone?: () => void;
  onUpdateCartItems?: (cartItems: CartItem[]) => void;
  onError?: (error: unknown) => void;
};

export type RequestSetCartBuyerIdentityPayload = RequestCartPayload

export type RequestClearCartPayload = RequestCartPayload

export type ClearShopifyCartRequestPayload = RequestCartPayload

export type RequestCreateShopifyCartPayload = RequestCartPayload

export type CreateShopifyCartRequestPayload = RequestCartPayload

export type GetShopifyCartRequestPayload = RequestCartPayload & {
  id: string | null;
}

export type RequestTransferOrMergeGuestCartPayload = RequestCartPayload & {
  guestToken: string;
  onCartMerge?: () => void;
};

export interface CartState {
  items: CartItemsHashmapCollection;
  totals: null | CartTotalsFrontend;
  isLoading: boolean;
  showAddingInProgress: CartItemsHashmapCollection;
  showDeletingInProgress: CartItemsHashmapCollection;
  purchasedItems: CartItemsHashmapCollection;
  isMergingCartItems: boolean;
  popoverVisibility: boolean;
  shopifyCart: ShopifyCartDto;
}

const initialState: CartState = {
  items: {},
  totals: null,
  isLoading: false,
  showAddingInProgress: {},
  showDeletingInProgress: {},
  purchasedItems: {},
  isMergingCartItems: false,
  popoverVisibility: false,
  shopifyCart: {
    id:'', lineItems:[], itemsSubtotal:0, order:'', total:0
  },
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    getShopifyCart: (
      state,
      action: PayloadAction<GetShopifyCartRequestPayload>
    ) => {
      console.log("getShopifyCart In CartSlice Hit");
    },
    removeLineItemFromShopify: (
      state,
      action: PayloadAction<string>
    ) => {console.log("removeLineItemFromShopify: ", action.payload);},
    updateCart: (state, action: PayloadAction<CartDto>) => {
      console.log(
        "CART STORE INDEX UPDATECART: ", action.payload.items
      );

      const { items, ...totals } = action.payload;
      state.totals = totals;

      const _items: CartItemsHashmapCollection = {};
      state.items = action.payload.items.reduce((items, item) => {
        items[item.guideId] = item;
        return items;
      }, _items);
    },
    requestSetCartBuyerIdentity: (state, action: PayloadAction<RequestSetCartBuyerIdentityPayload>) => {},
    requestAddItem: (state, action: PayloadAction<CartItem>) => {},
    addItem: (state, action: PayloadAction<CartItem>) => {
      state.items[action.payload.guideId] = action.payload;
    },
    requestClearCart: (state, action:PayloadAction<RequestClearCartPayload>) => {
      // remove items or create new cart?
      // state.shopifyCart = action.payload;
      console.log("CLEARING Cart");
    },
    setShopifyCart: (state, action:PayloadAction<ShopifyCartDto>) => {
      state.shopifyCart = action.payload
    },
    setIsAddingItem: (state, action: PayloadAction<CartItem>) => {
      state.showAddingInProgress[action.payload.guideId] = action.payload;
    },
    setItemIsAdded: (state, action: PayloadAction<CartItem>) => {
      delete state.showAddingInProgress[action.payload.guideId];
    },
    setCartIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addCartItemsToPurchased: (state, action: PayloadAction<void>) => {
      state.purchasedItems = state.items;
      state.items = {};
    },
    setPopoverVisibility: (state, action: PayloadAction<boolean>) => {
      state.popoverVisibility = action.payload;
    },
  },
});

export const actions = cartSlice.actions;
export const cartReducer = cartSlice.reducer;

const getCartStoreState = (state: AppState): CartState => state.cartState;

export const selectIsCartLoading = (state: AppState) =>
  state.cartState.isLoading;
export const selectIsCartEmpty = (state: AppState) =>
  !Object.keys(state.cartState.items).length;
export const selectCartItemsHashmapCollection = (state: AppState) =>
  state.cartState.items;
export const selectCartItemsList = (state: AppState) =>
  Object.values(state.cartState.items);
export const selectCartTotals = (state: AppState) => state.cartState.totals;
export const selectCartTotalsStringified = (state: AppState) => {
  const totals = state.cartState.totals;
  return totals ? stringifyCartTotals(totals) : null;
};
export const selectItemsBeingAddedToCart = (state: AppState) =>
  state.cartState.showAddingInProgress;
export const selectItemsBeingDeletedFromCart = (state: AppState) =>
  state.cartState.showDeletingInProgress;
export const selectPurchasedCartItemsList = createSelector(
  getCartStoreState,
  state => Object.values(state.purchasedItems)
);
export const selectIsMergingCartItems = createSelector(
  getCartStoreState,
  state => state.isMergingCartItems
);
export const selectCartPopoverVisibility = createSelector(
  getCartStoreState,
  state => state.popoverVisibility
);

export const selectShopifyCart = createSelector(
  getCartStoreState,
  state => state.shopifyCart
);

const stringifyCartTotals = (
  totals: CartTotalsFrontend
): CartTotalsStringified => {
  const { appliedCoupon, ...numericTotals } = totals;

  const stringifiedNumericEntries = Object.entries<number>(
    numericTotals as CartNumericTotals
  ).map(([key, value]) => [key, moneyAmountNumberToString(value)]);

  const stringifiedNumericTotals = Object.fromEntries(
    stringifiedNumericEntries
  ) as CartStringifiedNumericTotals;

  const stringifiedTotals = { ...stringifiedNumericTotals, appliedCoupon };
  return stringifiedTotals;
};

const moneyAmountNumberToString = (num: number | null | undefined): string => {
  return num ? `${num.toFixed(2)}` : '0.00';
};
