import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import { TrailCard, LoadedCard } from 'shared/Card/Card';
import {
  getIsFilteredStateSelector,
  getIsSearchedStateSelector,
} from 'store/TrailsStore/selectors';
import { actions } from 'store/TrailsStore/actions';

import { S } from './CardsMenu.styles';
import { CardsMenuProps } from './CardsMenu.interface';
import { AppDispatch } from 'store';
import {
  CartItemsHashmapCollection,
  selectCartItemsHashmapCollection,
  selectItemsBeingAddedToCart,
} from 'store/CartStore';
import { getDistanceUnitSelector } from 'store/Common/selectors';
import { AppState } from 'store/rootReducer';
import { FormattedMessage } from 'react-intl';

const CardsMenuComponent: React.FC<CardsMenuProps> = ({
  isOnSale,
  trailsList,
  areTrailsLoading,
  expandedGuideIds,
  handleSetExpandedGuideIds,
}) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const isFilteredState = useSelector(getIsFilteredStateSelector);
  const isSearchedState = useSelector(getIsSearchedStateSelector);
  const itemsInCart = useSelector<AppState, CartItemsHashmapCollection>(
    selectCartItemsHashmapCollection
  );
  const itemsBeingAddedToCart = useSelector<
    AppState,
    CartItemsHashmapCollection
  >(selectItemsBeingAddedToCart);
  const actualDistanceUnit = useSelector(getDistanceUnitSelector);

  const handleRemoveGuideFromInitiallyExpanded = useCallback(
    (id: string) => {
      expandedGuideIds &&
        handleSetExpandedGuideIds &&
        handleSetExpandedGuideIds(
          expandedGuideIds.filter(itemId => itemId !== id)
        );
    },
    [expandedGuideIds, handleSetExpandedGuideIds]
  );

  const handleAddGuideToInitiallyExpanded = useCallback(
    (id: string) => {
      expandedGuideIds &&
        handleSetExpandedGuideIds &&
        handleSetExpandedGuideIds([...expandedGuideIds, id]);
    },
    [expandedGuideIds, handleSetExpandedGuideIds]
  );

  useEffect(() => {
    dispatch(actions.setSelectedTrailGuideId(''));
  }, [dispatch]);

  const hanldeGoToGeoPage = (guideName: string) => {
    history.push(`/guides/${encodeURIComponent(guideName)}/geo`);
  };

  return (
    <S.Container>
      {!areTrailsLoading && (
        <>
          {trailsList.length > 0 &&
            trailsList.map(
              ({
                guideName,
                guidePromoText,
                guideIconImage,
                guideLength,
                guidePrice,
                childGuides,
                guideId,
                shopifyVariantId,
                guideIsFree,
                isPurchased,
                hasChildren,
                guideType,
                guideIsPurchaseable,
                guideHasOpenButton,
                parentGuides,
              }) => {
                return (
                  <S.CardWrapper key={guideId}>
                    <TrailCard
                      isOnSale={(guidePrice?.regular !== null && guidePrice?.final < guidePrice?.regular)}
                      shape="bumpy"
                      guideId={guideId}
                      shopifyVariantId={shopifyVariantId}
                      guideName={guideName}
                      guidePromoText={guidePromoText}
                      guideLength={{
                        distance: guideLength[actualDistanceUnit.name],
                        shortName: actualDistanceUnit.shortName,
                      }}
                      guideType={guideType}
                      guidePrice={guidePrice?.final} 
                      guidePriceCurrency={guidePrice?.currencyCode}
                      guideIconImage={guideIconImage}
                      guideIsFree={guideIsFree}
                      isPurchased={isPurchased}
                      itemsInCart={itemsInCart}
                      parentGuides={parentGuides}
                      itemsBeingAddedToCart={itemsBeingAddedToCart}
                      nestedCards={childGuides
                        ?.sort((a, b) => (a.guideId < b.guideId ? -1 : 1))
                        ?.sort(
                          (a, b) =>
                            Number(b.guideIsFree) - Number(a.guideIsFree)
                        )}
                      hasChildren={hasChildren}
                      isInitiallyExpanded={
                        expandedGuideIds && expandedGuideIds.includes(guideId)
                      }
                      handleRemoveGuideFromInitiallyExpanded={
                        handleRemoveGuideFromInitiallyExpanded
                      }
                      handleAddGuideToInitiallyExpanded={
                        handleAddGuideToInitiallyExpanded
                      }
                      onOpen={hanldeGoToGeoPage}
                      guideIsPurchaseable={guideIsPurchaseable}
                      guideHasOpenButton={guideHasOpenButton}
                    />
                  </S.CardWrapper>
                );
              }
            )}
          {!trailsList.length && (
            <>
              {isFilteredState || isSearchedState ? (
                <S.NotFoundText><FormattedMessage id="not_found"/></S.NotFoundText>
              ) : (
                <div><FormattedMessage id="no_guides"/></div>
              )}
            </>
          )}
        </>
        // if it's free or purchased, go to guide use link
      )}
      {areTrailsLoading && (
        <>
          <LoadedCard />
          <LoadedCard />
          <LoadedCard />
        </>
      )}
    </S.Container>
  );
};

export const CardsMenu = memo(CardsMenuComponent, isEqual);
