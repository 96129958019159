import styled from 'styled-components';

export const S = {
  Container: styled.div`
    padding: 0px 10px;
  `,

  CardWrapper: styled.div`
    margin-bottom: 10px;
  `,
  NotFoundText: styled.div`
    font-weight: bold;
    text-align: center;
  `,
};
