import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash.isequal';
import { actions } from 'store/GuideUse/actions';

import { S } from './DistanceToPoint.styles';
import {
  DistanceToPointProps,
  NeighbourWaypoints,
} from './DistanceToPoint.interface';
import { GeoWaypoint } from 'containers/GuideUse/GuideUse.interface';
import { FormattedMessage } from 'react-intl';
import { getWaypointIcon } from 'utils/waypointHelper';

const DistanceToPointComponent: React.FC<DistanceToPointProps> = ({
  distanceUnit,
  currentWaypoint,
  waypoints,
}) => {
  const dispatch = useDispatch();
  const [neighbourWaypoints, setNeighbourWaypoints] =
    useState<NeighbourWaypoints>({
      prev: { data: {}, distance: 0 },
      next: { data: {}, distance: 0 },
    });

  const findCurrentWaypointIndex = (
    currentWaypoint: GeoWaypoint,
    waypoints: GeoWaypoint[]
  ) => {
    return waypoints.findIndex(
      (waypoint: GeoWaypoint) => waypoint.id === currentWaypoint.id
    );
  };

  const calculateDistanceToNextPoint = useCallback(
    (currentDistance: number, nextDistance: number): number => {
      return nextDistance - currentDistance;
    },
    []
  );

  const calculateDistanceToPrevPoint = useCallback(
    (currentDistance: number, prevDistance: number): number => {
      return currentDistance - prevDistance;
    },
    []
  );

  useEffect(() => {
    const currentWaypointIndex = findCurrentWaypointIndex(
      currentWaypoint,
      waypoints
    );

    const prevWaypoint = waypoints[currentWaypointIndex - 1];
    const nextWaypoint = waypoints[currentWaypointIndex + 1];

    setNeighbourWaypoints(() => ({
      prev: {
        data: prevWaypoint,
        distance: prevWaypoint
          ? calculateDistanceToPrevPoint(
              currentWaypoint.waypointDistance || 0,
              prevWaypoint.waypointDistance || 0
            )
          : 0,
      },
      next: {
        data: nextWaypoint,
        distance: nextWaypoint
          ? calculateDistanceToNextPoint(
              currentWaypoint.waypointDistance || 0,
              nextWaypoint.waypointDistance || 0
            )
          : 0,
      },
    }));
  }, [
    currentWaypoint,
    waypoints,
    calculateDistanceToPrevPoint,
    calculateDistanceToNextPoint,
  ]);

  const handleSwitchToNextWaypoint = () => {
    const nextWaypointId = neighbourWaypoints.next?.data.id;

    dispatch(actions.setSelectedWaypoint(nextWaypointId as string));
  };

  const handleSwitchToPrevWaypoint = () => {
    const prevWaypointId = neighbourWaypoints.prev?.data.id;

    dispatch(actions.setSelectedWaypoint(prevWaypointId as string));
  };

  return (
    <>
      {(neighbourWaypoints.prev?.data || neighbourWaypoints.next?.data) && (
        <S.Container>
          {currentWaypoint.waypointDistance && neighbourWaypoints.prev?.data && (
            <S.LeftSideControls>
              <S.LeftOutlined onClick={handleSwitchToPrevWaypoint} />
              <S.Distance>
                {(
                  neighbourWaypoints.prev.distance / distanceUnit.valueInMeters
                )?.toFixed(1)}
                <FormattedMessage id={distanceUnit.shortName}/>
              </S.Distance>
            </S.LeftSideControls>
          )}
          <S.Icon src={getWaypointIcon("poi")} />
          {currentWaypoint.waypointDistance && neighbourWaypoints.next?.data && (
            <S.RightSideControls>
              <S.Distance>
                {(
                  neighbourWaypoints.next.distance / distanceUnit.valueInMeters
                )?.toFixed(1)}
                <FormattedMessage id={distanceUnit.shortName}/>
              </S.Distance>
              <S.RightOutlined onClick={handleSwitchToNextWaypoint} />
            </S.RightSideControls>
          )}
        </S.Container>
      )}
    </>
  );
};

export const DistanceToPoint = memo(DistanceToPointComponent, isEqual);
