import axios from 'axios';
import { LocalStorageManager } from 'services/LocalStorage/LocalStorageManager';
import { onCountrySelectedFunc } from './settingsUtils';

async function getGeoCountryCode(): Promise<string | null> {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      const res = await axios.get('https://ipapi.co/json/', { headers });
      if (res.status === 200) {
        console.log('getCountryCode(): ' + res);
        return res.data.country_code;
      }
    } 
    catch (error) {
      console.log(error);
    }
    return "US";
}

export async function getResultCountryCode() : Promise<string | null> {
    const localStorage = LocalStorageManager.getInstance();
    const selectedCountryCode: string | null = localStorage.getItem('shopifyCountry');

    var resCountryCode = selectedCountryCode;

    if (!selectedCountryCode){
        let geoCountryCode = localStorage.getItem('geoCountryCode');
        if (geoCountryCode){
            resCountryCode = geoCountryCode
        }
        else{
            geoCountryCode = await getGeoCountryCode()
            if (geoCountryCode){
              localStorage.setItem('geoCountryCode', geoCountryCode);
              resCountryCode = geoCountryCode
            }
        }

        onCountrySelectedFunc(resCountryCode);
    }
    return resCountryCode
}
  